/**
 * Home
 *
 * Gestion des events sur la homepage
 * Polaroid et ScrollMagic
 *
 */

import TweenMax from 'TweenMax';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
// debug mode
// import 'debug.addIndicators';

import $ from "jquery";

let isInitialized = false;
let scrollMagic;

function initParallax() {

    if (breakpoints.isDesktop()) {


        if(!isInitialized) {
            var controller = new ScrollMagic.Controller();

            $('[data-role="trigger-parallax"]').each(function() {
                let $parallaxItem = $(this);
                let moveX = $parallaxItem.data('move-x');
                let moveY = $parallaxItem.data('move-y');

                let objectMove = {};
                if (moveX) {
                    objectMove.x = moveX
                }
                if (moveY) {
                    objectMove.y = moveY
                }
                // build scene
                let scene = new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.60,
                    duration: $parallaxItem.data('duration')
                })
                    .setTween(`[data-role="${$parallaxItem.attr('data-target')}"]`, objectMove) // trigger a TweenMax.to tween
                    // debug mode
                    //.addIndicators({name: "1 (duration: 300)"})
                    .addTo(controller);
            });

            isInitialized = true;
            scrollMagic = controller;
        }
    } else {
        if (isInitialized) {
            scrollMagic.destroy(true);
            isInitialized = false;
        }
    }
}


$(document).ready(function(){

    initParallax();
    $(window).resize(function(){
        initParallax();
    });
});
